/* MDB HOVER EFFECTS */

// MDB Hover effects
.view {
    overflow: hidden;
    position: relative;
    cursor: default;
    .mask,
    .content {
        width: 100%;
        height: 100%;
        position: absolute;
        overflow: hidden;
        top: 0;
        left: 0
    }
    img {
        display: block;
        position: relative
    }
}

// Overlays
.overlay {
    &:hover .mask {
        opacity: 1;
    }
    img {
        transition: all 0.2s linear;
    }
    .mask {
        opacity: 0;
        transition: all 0.4s ease-in-out;
    }
}

//Overlay patterns
.pattern-1 {
    background: url(/img/overlays/01.png);
}
.pattern-2 {
    background: url(/img/overlays/02.png);
}
.pattern-3 {
    background: url(/img/overlays/03.png);
}
.pattern-4 {
    background: url(/img/overlays/04.png);
}
.pattern-5 {
    background: url(/img/overlays/05.png);
}
.pattern-6 {
    background: url(/img/overlays/06.png);
}
.pattern-7 {
    background: url(/img/overlays/07.png);
}
.pattern-8 {
    background: url(/img/overlays/08.png);
}
.pattern-9 {
    background: url(/img/overlays/09.png);
}
// Overlay masks
// Light overlays
.hm-blue-light .mask {
    background-color: rgba(3, 169, 244, 0.3);
}

.hm-red-light .mask {
    background-color: rgba(244, 67, 54, 0.3);
}

.hm-pink-light .mask {
    background-color: rgba(233, 30, 99, 0.3);
}

.hm-purple-light .mask {
    background-color: rgba(156, 39, 176, 0.3);
}

.hm-indigo-light .mask {
    background-color: rgba(63, 81, 181, 0.3);
}

.hm-cyan-light .mask {
    background-color: rgba(0, 188, 212, 0.3);
}

.hm-teal-light .mask {
    background-color: rgba(0, 150, 136, 0.3);
}

.hm-green-light .mask {
    background-color: rgba(76, 175, 80, 0.3);
}

.hm-lime-light .mask {
    background-color: rgba(205, 220, 57, 0.3);
}

.hm-yellow-light .mask {
    background-color: rgba(255, 235, 59, 0.3);
}

.hm-orange-light .mask {
    background-color: rgba(255, 152, 0, 0.3);
}

.hm-brown-light .mask {
    background-color: rgba(121, 85, 72, 0.3);
}

.hm-grey-light .mask {
    background-color: rgba(158, 158, 158, 0.3);
}

.hm-bluegrey-light .mask {
    background-color: rgba(96, 125, 139, 0.3);
}

.hm-black-light .mask {
    background-color: rgba(0, 0, 0, 0.3);
}

.hm-stylish-light .mask {
    background-color: rgba(62, 69, 81, 0.3);
}

.hm-white-light .mask {
    background-color: rgba(255, 255, 255, 0.3);
}

// Strong overlays
.hm-blue-strong .mask {
    background-color: rgba(3, 169, 244, 0.7);
}

.hm-red-strong .mask {
    background-color: rgba(244, 67, 54, 0.7);
}

.hm-pink-strong .mask {
    background-color: rgba(233, 30, 99, 0.7);
}

.hm-purple-strong .mask {
    background-color: rgba(156, 39, 176, 0.7);
}

.hm-indigo-strong .mask {
    background-color: rgba(63, 81, 181, 0.7);
}

.hm-cyan-strong .mask {
    background-color: rgba(0, 188, 212, 0.7);
}

.hm-teal-strong .mask {
    background-color: rgba(0, 150, 136, 0.7);
}

.hm-green-strong .mask {
    background-color: rgba(76, 175, 80, 0.7);
}

.hm-lime-strong .mask {
    background-color: rgba(205, 220, 57, 0.7);
}

.hm-yellow-strong .mask {
    background-color: rgba(255, 235, 59, 0.7);
}

.hm-orange-strong .mask {
    background-color: rgba(255, 152, 0, 0.7);
}

.hm-brown-strong .mask {
    background-color: rgba(121, 85, 72, 0.7);
}

.hm-grey-strong .mask {
    background-color: rgba(158, 158, 158, 0.7);
}

.hm-bluegrey-strong .mask {
    background-color: rgba(96, 125, 139, 0.7);
}

.hm-black-strong .mask {
    background-color: rgba(0, 0, 0, 0.7);
}

.hm-stylish-strong .mask {
    background-color: rgba(62, 69, 81, 0.7);
}

.hm-white-strong .mask {
    background-color: rgba(255, 255, 255, 0.7);
}

// Light overlays
.hm-blue-slight .mask {
    background-color: rgba(3, 169, 244, 0.1);
}

.hm-red-slight .mask {
    background-color: rgba(244, 67, 54, 0.1);
}

.hm-pink-slight .mask {
    background-color: rgba(233, 30, 99, 0.1);
}

.hm-purple-slight .mask {
    background-color: rgba(156, 39, 176, 0.1);
}

.hm-indigo-slight .mask {
    background-color: rgba(63, 81, 181, 0.1);
}

.hm-cyan-slight .mask {
    background-color: rgba(0, 188, 212, 0.1);
}

.hm-teal-slight .mask {
    background-color: rgba(0, 150, 136, 0.1);
}

.hm-green-slight .mask {
    background-color: rgba(76, 175, 80, 0.1);
}

.hm-lime-slight .mask {
    background-color: rgba(205, 220, 57, 0.1);
}

.hm-yellow-slight .mask {
    background-color: rgba(255, 235, 59, 0.1);
}

.hm-orange-slight .mask {
    background-color: rgba(255, 152, 0, 0.1);
}

.hm-brown-slight .mask {
    background-color: rgba(121, 85, 72, 0.1);
}

.hm-grey-slight .mask {
    background-color: rgba(158, 158, 158, 0.1);
}

.hm-bluegrey-slight .mask {
    background-color: rgba(96, 125, 139, 0.1);
}

.hm-black-slight .mask {
    background-color: rgba(0, 0, 0, 0.1);
}

.hm-stylish-slight .mask {
    background-color: rgba(62, 69, 81, 0.1);
}

.hm-white-slight .mask {
    background-color: rgba(255, 255, 255, 0.1);
}

// Zoom
.hm-zoom img {
    transition: all 0.2s linear;
}

.hm-zoom:hover img {
    transform: scale(1.1);
}

.hm-zoom:hover .mask {
    opacity: 1;
}