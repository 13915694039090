/*********************
  Tooltip
**********************/

.tooltip {
    line-height: 1.5;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
}

.tooltip .tooltip-arrow {
    border-top-color: #222;
    margin: 1px;
}

.tooltip > .tooltip-inner {
    background-color: #222;
    color: #FFFFFF;
    border: 1px solid #222;
    padding: 15px;
    font-size: 20px;
    border-radius: 0px;
    
}
 
.tooltip.right .tooltip-arrow {
    top: 50%;
    left: 0;
    margin-top: -5px;
    border-right-color: #000000;
    border-width: 5px 5px 5px 0;
}

.popover {
    //transform: scale(0);
    //transform-origin: top center;
    //will-change: transform;
    z-index: 999;
    //background: $tooltip-background-color;
    color: $tooltip-text-color;
    display: inline-block;
    font-size: $tooltip-font-size;  
    line-height: 1.5;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    max-width: 170px;
    padding: 8px;
    text-align: center;
    background-color: #222;
}

.popover-title {
    background-color: #222;
    color: #FFFFFF;
    border: 1px solid #222;
    padding: 15px;
    font-size: 20px;
    border-radius: 0px;
}

.popover-content {
    background-color: #222;
    color: #FFFFFF;
    border: 1px solid #222;
    padding: 15px;
    font-size: 20px;
    border-radius: 0px;
}

.popover > .arrow,
.popover > .arrow:after {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    color: #222;
}

.popover > .arrow {
    border-width: 11px;
}

.popover > .arrow:after {
    content: "";
    border-width: 10px;
}

.popover.top > .arrow {
    bottom: -11px;
    left: 50%;
    margin-left: -11px;
    border-top-color: #999;
    border-top-color: rgba(0, 0, 0, .25);
    border-bottom-width: 0;
}

.popover.top > .arrow:after {
    bottom: 1px;
    margin-left: -10px;
    content: " ";
    border-top-color: #222;
    border-bottom-width: 0;
}

.popover.right > .arrow {
    top: 50%;
    left: -11px;
    margin-top: -11px;
    border-right-color: #999;
    border-right-color: rgba(0, 0, 0, .25);
    border-left-width: 0;
}

.popover.right > .arrow:after {
    bottom: -10px;
    left: 1px;
    content: " ";
    border-right-color: #222;
    border-left-width: 0;
}

.popover.bottom > .arrow {
    top: -11px;
    left: 50%;
    margin-left: -11px;
    border-top-width: 0;
    border-bottom-color: #999;
    border-bottom-color: rgba(0, 0, 0, .25);
}

.popover.bottom > .arrow:after {
    top: 1px;
    margin-left: -10px;
    content: " ";
    border-top-width: 0;
    border-bottom-color: #222;
}

.popover.left > .arrow {
    top: 50%;
    right: -11px;
    margin-top: -11px;
    border-right-width: 0;
    border-left-color: #999;
    border-left-color: rgba(0, 0, 0, .25);
}

.popover.left > .arrow:after {
    right: 1px;
    bottom: -10px;
    content: " ";
    border-right-width: 0;
    border-left-color: #222;
}

.mdl-tooltip {
    transform: scale(0);
    transform-origin: top center;
    will-change: transform;
    z-index: 999;
    background: $tooltip-background-color;
    border-radius: 2px;
    color: $tooltip-text-color;
    display: inline-block;
    font-size: $tooltip-font-size;
    font-weight: 500;
    line-height: 14px;
    max-width: 170px;
    position: fixed;
    top: -500px;
    left: -500px;
    padding: 8px;
    text-align: center;
}

.mdl-tooltip.is-active {
    animation: pulse 200ms $animation-curve-linear-out-slow-in forwards;
}

.mdl-tooltip--large {
    line-height: 14px;
    font-size: $tooltip-font-size-large;
    padding: 16px;
}

@keyframes pulse {
    0% {
        transform: scale(0);
        opacity: 0;
    }
    50% {
        // Fixes a weird bug with the interaction between Safari and the result of
        // the SASS compilation for the animation.
        // Essentially, we need to make sure that "50%" and "100%" don't get merged
        // into a single "50%, 100%" entry, so we need to avoid them having any
        // matching properties.
        transform: scale(0.99);
    }
    100% {
        transform: scale(1);
        opacity: 1;
        visibility: visible;
    }
}