// Center text on mobile
.center-on-small-only {
    @media #{$medium-and-down} {
        text-align: center;
    }
}

// Maring & padding manipulation
.no-margin {
    margin: 0;
}
.no-top-mar {
    margin-top: 0!important; 
}

.no-gutter > [class*=col-] {
    padding-right: 0;
    padding-left: 0;
}

.no-padding {
    padding: 0;
}

.no-pad-mar {
    margin: 0;
    padding: 0;
}

.extra-padding-05 {
    padding: 0.5rem;
}

.extra-padding-1 {
    padding: 1rem;
}
.extra-margin-1 {
    padding: 1rem;
}
.extra-margin-2 {
    padding: 2rem;
}

// Extra space
.space-30 {
    height: 30px;
}

.space-50 {
    height: 30px;
}

.verticalcenter {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}

// Divider
.divider-new {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 2.5em;
    font-weight: 300;
    margin-top: 45px;
    margin-bottom: 45px;
}

.divider-new::before {
    content: '';
     height: 1.5px;
    background: #666;
    flex: 1;
    margin: 0 .45em 0 0;
}

.divider-new::after {
    content: '';
    height: 1.5px;
    background: #666;
    flex: 1;
    margin: 0 0 0 .45em;
}

// Section title
.section-title {
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 3rem;
    padding: 0.7rem;
    small {
        text-transform: none;
        padding-left: 7px;
        margin-left: 10px;
    }
}

.st-indigo {
    border-left: 4px solid blue;
    small {
        border-left: 2px solid blue;
    }
}

.st-red {
    border-left: 4px solid red;
    small {
        border-left: 2px solid red;
    }
}

.st-teal {
    border-left: 4px solid #009688;
    small {
        border-left: 2px solid #009688;
    }
}

.st-orange {
    border-left: 4px solid #ff6f00;
    small {
        border-left: 2px solid #ff6f00;
    }
}

.st-blue {
    border-left: 4px solid #2196f3;
    small {
        border-left: 2px solid #2196f3;
    }
}

.divider-short {
    max-width: 50px;
    border-color: $primary-color;
    border-width: 3px;
} 