/*********************
  Cards
**********************/

.card-panel {
    transition: box-shadow .25s;
    padding: $card-padding;
    margin: $element-top-margin 0 $element-bottom-margin 0;
    border-radius: 2px;
    @extend .z-depth-1;
    background-color: $card-bg-color;
}

.card {
    position: relative;
    overflow: hidden;
    background-color: $card-bg-color;
    transition: box-shadow .25s;
    border-radius: 2px;
    @extend .z-depth-1;
    .card-title {
        color: $card-bg-color;
        font-size: 24px;
        font-weight: 300;
        &.activator {
            cursor: pointer;
        }
    }
    // Card Sizes
    &.small,
    &.medium,
    &.large {
        position: relative;
        .card-image {
            overflow: hidden;
        }
        .card-content {
            overflow: hidden;
        }
        .card-action {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
        }
    }
    &.small {
        height: 300px;
        .card-image {
            height: 150px;
        }
        .card-content {
            height: 150px;
        }
    }
    &.medium {
        height: 400px;
        .card-image {
            height: 250px;
        }
        .card-content {
            height: 150px;
        }
    }
    &.large {
        height: 500px;
        .card-image {
            height: 330px;
        }
        .card-content {
            height: 170px;
        }
    }
    .card-image {
        position: relative;
        // Image background for content
        img {
            border-radius: 2px 2px 0 0;
            position: relative;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            width: 100%;
        }
        .card-title {
            position: absolute;
            bottom: 0;
            left: 0;
            padding: $card-padding;
        }
    }
    .card-content {
        padding: $card-padding;
        border-radius: 0 0 2px 2px;
        h5 {
            padding-bottom: 10px;
            border-bottom: 1px solid #eeeeee;
        }
        p {
            margin: 0;
            color: inherit;
        }
        .card-title {
            line-height: 48px;
        }
    }
    .card-action {
        border-top: 1px solid rgba(160, 160, 160, .2);
        padding: $card-padding;
        a {
            color: $card-link-color;
            margin-right: $card-padding;
            @include transition(color .3s ease);
            text-transform: uppercase;
            &:hover {
                color: $card-link-color-light;
            }
        }
    }
    .card-btn {
        border-top: 1px solid #eee;
        padding-top: 10px;
        margin-top: 10px;
        margin-bottom: 10px;
    }
}