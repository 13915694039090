/*********************
  Typography
**********************/

a {
    text-decoration: none;
}

html {
    line-height: 1.5;
    @media only screen and (min-width: 0) {
        font-size: 14px;
    }
    @media only screen and (min-width: $medium-screen) {
        font-size: 14.5px;
    }
    @media only screen and (min-width: $large-screen) {
        font-size: 15px;
    }
    font-family: "Roboto",
    sans-serif;
    font-weight: normal;
    color: $off-black;
}

p {
    font-size: 1.25rem;
    line-height: 1.8;
    font-weight: 300;
}

h1,
h2,
h3,
h4,
h5,
h5 {
    font-weight: 300;
}

// Header Styles
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
    font-weight: inherit;
}

h1 {
    font-size: $h1-fontsize;
    line-height: 110%;
    margin: ($h1-fontsize / 2) 0 ($h1-fontsize / 2.5) 0;
}

h2 {
    font-size: $h2-fontsize;
    line-height: 110%;
    margin: ($h2-fontsize / 2) 0 ($h2-fontsize / 2.5) 0;
}

h3 {
    font-size: $h3-fontsize;
    line-height: 110%;
    margin: ($h3-fontsize / 2) 0 ($h3-fontsize / 2.5) 0;
}

h4 {
    font-size: $h4-fontsize;
    line-height: 110%;
    margin: ($h4-fontsize / 2) 0 ($h4-fontsize / 2.5) 0;
}

h5 {
    font-size: $h5-fontsize;
    line-height: 110%;
    margin: ($h5-fontsize / 2) 0 ($h5-fontsize / 2.5) 0;
}

h6 {
    font-size: $h6-fontsize;
    line-height: 110%;
    margin: ($h6-fontsize / 2) 0 ($h6-fontsize / 2.5) 0;
}

// Text Styles
em {
    font-style: italic;
}

strong {
    font-weight: 500;
}

small {
    font-size: 75%;
}

.thin-100 {
    font-weight: 100;
}

.thin-100-italic {
    font-weight: 100;
    font-style: italic;
}

.light-300 {
    font-weight: 300;
}

.light-300-italic {
    font-weight: 300;
    font-style: italic;
}

.normal-400 {
    font-weight: 400; 
}

.normal-400-italic {
    font-weight: 400;
    font-style: italic;
}

.medium-500 {
    font-weight: 500;
}

.medium-500-italic {
    font-weight: 500;
    font-style: italic;
}

.bold-700 {
    font-weight: 700;
}

.bold-700-italic {
    font-weight: 700;
    font-style: italic;
}

.ultra-bold-900 {
    font-weight: 900;
}

.ultra-bold-900-italic {
    font-weight: 900;
    font-style: italic;
}

.flow-text {
    font-weight: 300;
    $i: 0;
    @while $i <=$intervals {
        @media only screen and (min-width: 360 + ($i * $interval-size)) {
            font-size: 1.2rem * (1 + (.02 * $i));
        }
        $i: $i + 1;
    }
    // Handle below 360px screen
    @media only screen and (max-width: 360px) {
        font-size: 1.2rem;
    }
}

/* Responsive Headings */
@media only screen and (max-width: 480px) {
     .h1-responsive {
        font-size:2.18rem;
    }
     .h2-responsive {
        font-size: 1.84rem;
    }
     .h3-responsive {
        font-size: 1.7rem; 
    }
     .h4-responsive {
        font-size: 1.3rem;
    }
     .h5-responsive {
        font-size: 1.1rem; 
    }
}


/* Extra Small Devices, Phones */

@media only screen and (min-width: 480px) {
     .h1-responsive {
        font-size:2.28rem;
    }
     .h2-responsive {
        font-size: 1.94rem;
    }
     .h3-responsive {
        font-size: 1.6rem;
    }
     .h4-responsive {   
        font-size: 1.4rem;
    }
     .h5-responsive {
        font-size: 1.2rem;
    }
}


/* Small Devices, Tablets */

@media only screen and (min-width: 768px) {
    .h1-responsive {
        font-size:2.92rem;
    }
     .h2-responsive {
        font-size: 2.28rem;
    }
     .h3-responsive {
        font-size: 1.94rem;
    }
     .h4-responsive {
        font-size: 1.4rem;
    }
     .h5-responsive {
        font-size: 1.15rem;
    }
}


/* Medium Devices, Desktops */

@media only screen and (min-width: 992px) { 
    .h1-responsive {
        font-size:  3.56rem;
    }
     .h2-responsive {
        font-size: 2.92rem;
    }
     .h3-responsive {
        font-size: 2.28rem;
    }
     .h4-responsive {
        font-size: 1.64rem;
    }
     .h5-responsive {
        font-size: 1.2rem;
    }
}


/* Large Devices, Wide Screens */

@media only screen and (min-width: 1200px) {
    .h1-responsive {
        font-size: 4.2rem;
    }
     .h2-responsive {
        font-size: 3.56rem;
    }
     .h3-responsive {
        font-size: 2.92rem;
    }
     .h4-responsive {
        font-size: 2.28rem;
    }
     .h5-responsive {
        font-size: 1.64rem;
    }
    
}