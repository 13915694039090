/*********************
  Panels
**********************/

.panel-default {
  .panel-heading {
    background-color: transparent;
    border-radius: 0px;
  }
}

.panel-group {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  .panel {
    border-radius: 0px;
  }
  .panel+.panel {
    margin-top: 0px;
    border-top: 0px;
    border-bottom: 1px solid #ddd;
  }
}

