/*********************
Dropdowns
**********************/

.dropdown-content {
  @extend .z-depth-1;
  background-color: $dropdown-bg-color;
  margin: 0;
  display: none;
  min-width: 100px;
  max-height: 650px;
  overflow-y: auto;
  opacity: 0;
  position: absolute;
  z-index: 999;
  will-change: width, height;

  li {
    clear: both;
    color: $off-black;
    cursor: pointer;
    line-height: 1.5rem;
    width: 100%;
    text-align: left;
    text-transform: none;

    &:hover, &.active {
      background-color: $dropdown-hover-bg-color;
    }

    & > a, & > span {
      font-size: 1rem;
      color: $dropdown-color;
      display: block;
      padding: 1rem 1rem;
    }

    // Icon alignment override
    & > a > i {
      height: inherit;
      line-height: inherit;
    }
  }
}

.dropdown-menu {
    border-radius: 0;
    @extend .z-depth-1;
    
    li {
        
    clear: both;
    color: $off-black;
    cursor: pointer;
    line-height: 1.5rem;
    width: 100%;
    text-align: left;
    text-transform: none;

    &:hover, &.active {
      background-color: $dropdown-hover-bg-color;
    }

    & > a, & > span {
      font-size: 1rem;
      /*color: $dropdown-color;*/
      color: #333333;
      display: block;
      padding: 1rem 1rem;
      -webkit-transition: ease-in-out 0.5s;
      -moz-transition: ease-in-out 0.5s;
      -ms-transition: ease-in-out 0.5s;
      -o-transition: ease-in-out 0.5s;
      transition: ease-in-out 0.5s;
    }
      // Add hover to dropdown
      & > a:hover, & > span:hover {
        color: #B39141;
        -webkit-transition: ease-in-out 0.3s;
        -moz-transition: ease-in-out 0.3s;
        -ms-transition: ease-in-out 0.3s;
        -o-transition: ease-in-out 0.3s;
        transition: ease-in-out 0.3s;
      }

    // Icon alignment override
    & > a > i {
      height: inherit;
      line-height: inherit;
    }
  }
  
}
