/***************************
Forms basic
****************************/

// Style Placeholders
::-webkit-input-placeholder {
    color: lighten($input-border-color, 20%);
}

:-moz-placeholder {
    /* Firefox 18- */
    color: lighten($input-border-color, 20%);
}

::-moz-placeholder {
    /* Firefox 19+ */
    color: lighten($input-border-color, 20%);
}

:-ms-input-placeholder {
    color: lighten($input-border-color, 20%);
}

// Text inputs
input[type=text],
input[type=password],
input[type=email],
input[type=url],
input[type=time],
input[type=date],
input[type=datetime-local],
input[type=tel],
input[type=number],
input[type=search-md],
textarea.materialize-textarea {
    // General Styles
    background-color: transparent;
    border: none;
    border-bottom: 1px solid $input-border-color;
    border-radius: 0;
    outline: none;
    height: 3rem;
    width: 100%;
    font-size: 1rem;
    margin: 0 0 15px 0;
    padding: 0;
    box-shadow: none;
    @include box-sizing(content-box);
    transition: all .3s;
    // Disabled input style
    &:disabled,
    &[readonly="readonly"] {
        color: $input-disabled-color;
        border-bottom: 1px dotted $input-disabled-color;
    }
    // Disabled label style
    &:disabled+label,
    &[readonly="readonly"]+label {
        color: $input-disabled-color;
    }
    // Focused input style
    &:focus:not([readonly]) {
        border-bottom: 1px solid $input-focus-color;
        box-shadow: 0 1px 0 0 $input-focus-color;
    }
    // Focused label style
    &:focus:not([readonly])+label {
        color: $input-focus-color;
    }
    // Valid Input Style
    &.valid,
    &:focus.valid {
        border-bottom: 1px solid $input-success-color;
        box-shadow: 0 1px 0 0 $input-success-color;
    }
    // Custom Success Message
    &.valid + label:after,
    &:focus.valid + label:after {
        content: attr(data-success);
        color: $input-success-color;
        opacity: 1;
    }
    // Invalid Input Style
    &.invalid,
    &:focus.invalid {
        border-bottom: 1px solid $input-error-color;
        box-shadow: 0 1px 0 0 $input-error-color;
    }
    // Custom Error message
    &.invalid + label:after,
    &:focus.invalid + label:after {
        content: attr(data-error);
        color: $input-error-color;
        opacity: 1;
    }
    // Form Message Shared Styles
    & + label:after {
        display: block;
        content: "";
        position: absolute;
        top: 65px;
        opacity: 0;
        transition: .2s opacity ease-out, .2s color ease-out;
    }
}


select:focus {
    outline: 1px solid lighten($secondary-color, 47%);
}

button:focus {
    outline: none;
    background-color: lighten($button-color, 4%);
}

label {
    font-size: $label-font-size;
    color: $input-border-color;
}
 
// Styling for input field wrapper
.input-field {
    position: relative;
    margin-top: 1rem;
    label {
        color: $input-border-color;
        font-size: 1rem;
        cursor: text;
    }
    label.input-label {
        position: absolute;
        top: 0.8rem;
        left: ($gutter-width / 2) + 1.2;
        @include transition(.2s ease-out);
    }
    label.active {
        font-size: $label-font-size;
        @include transform(translateY(-140%));
        left: ($gutter-width / 2);
    }
    // Prefix Icons
    .prefix {
        position: absolute;
        width: 3rem;
        font-size: 2rem;
        @include transition(color .2s);
        &.active {
            color: $input-focus-color;
        }
    }
    .prefix ~ input,
    .prefix ~ textarea {
        margin-left: 3rem;
        width: 92%;
        width: calc(100% - 3rem);
    }
    .prefix ~ textarea {
        padding-top: .8rem;
    }
    .prefix ~ label {
        margin-left: 3rem;
    }
    @media #{$medium-and-down} {
        .prefix ~ input {
            width: 86%;
            width: calc(100% - 3rem);
        }
    }
    @media #{$small-and-down} {
        .prefix ~ input {
            width: 80%;
            width: calc(100% - 3rem);
        }
    }
} 

// Search Field
.input-field input[type=search-md] {
    display: block;
    line-height: inherit;
    padding-left: 4rem;
    width: calc(100% - 4rem);
    &:focus {
        background-color: $input-bg-color;
        border: 0;
        box-shadow: none;
        color: #444;
        & + label i,
        & ~ .mdi-navigation-close {
            color: #444;
        }
    }
    & + label {
        left: 1rem;
    }
    & ~ .mdi-navigation-close {
        position: absolute;
        top: 0;
        right: 1rem;
        color: transparent;
        cursor: pointer;
        font-size: 2rem;
        transition: .3s color;
    }
}

// Default textarea
textarea {
    width: 100%;
    height: 3rem;
    background-color: transparent;
    &.materialize-textarea {
        overflow-y: hidden;
        /* prevents scroll bar flash */
        padding: 1.6rem 0;
        /* prevents text jump on Enter keypress */
        resize: none;
        min-height: 3rem;
    }
}

// For textarea autoresize
.hiddendiv {
    display: none;
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow-wrap: break-word;
    /* future version of deprecated 'word-wrap' */
    padding-top: 1.2rem;
    /* prevents text jump on Enter keypress */
}