/*********************
  Roboto font
**********************/


@font-face {
    font-family: "Roboto";
    src: url('#{$roboto-font-path}Roboto-Thin.eot');
    src: url('#{$roboto-font-path}Roboto-Thin.eot?#iefix') format('embedded-opentype'),
        url('#{$roboto-font-path}Roboto-Thin.woff') format('woff'),
        url('#{$roboto-font-path}Roboto-Thin.ttf') format('truetype'),
        url('#{$roboto-font-path}Roboto-Thin.svg#oswald') format('svg');
    font-weight: 100;
}
@font-face {
    font-family: "Roboto";
    src: url('#{$roboto-font-path}Roboto-Light.eot');
    src: url('#{$roboto-font-path}Roboto-Light.eot?#iefix') format('embedded-opentype'),
        url('#{$roboto-font-path}Roboto-Light.woff') format('woff'),
        url('#{$roboto-font-path}Roboto-Light.ttf') format('truetype'),
        url('#{$roboto-font-path}Roboto-Light.svg#oswald') format('svg');
    font-weight: 300;
}

@font-face {
    font-family: "Roboto";
    src: url('#{$roboto-font-path}Roboto-Regular.eot');
    src: url('#{$roboto-font-path}Roboto-Regular.eot?#iefix') format('embedded-opentype'),
        url('#{$roboto-font-path}Roboto-Regular.woff') format('woff'),
        url('#{$roboto-font-path}Roboto-Regular.ttf') format('truetype'),
        url('#{$roboto-font-path}Roboto-Regular.svg#oswald') format('svg');
    font-weight: 400;
}

@font-face {
    font-family: "Roboto";
    src: url('#{$roboto-font-path}Roboto-Medium.eot');
    src: url('#{$roboto-font-path}Roboto-Medium.eot?#iefix') format('embedded-opentype'),
        url('#{$roboto-font-path}Roboto-Medium.woff') format('woff'),
        url('#{$roboto-font-path}Roboto-Medium.ttf') format('truetype'),
        url('#{$roboto-font-path}Roboto-Medium.svg#oswald') format('svg');
    font-weight: 500;
}

@font-face {
    font-family: "Roboto";
    src: url('#{$roboto-font-path}Roboto-Bold.eot');
    src: url('#{$roboto-font-path}Roboto-Bold.eot?#iefix') format('embedded-opentype'),
        url('#{$roboto-font-path}Roboto-Bold.woff') format('woff'),
        url('#{$roboto-font-path}Roboto-Bold.ttf') format('truetype'),
        url('#{$roboto-font-path}Roboto-Bold.svg#oswald') format('svg');
    font-weight: 700;
}