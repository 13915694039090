/*********************
  Parallax
**********************/

.parallax {
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    position: relative;
    padding-top: 3em;
    padding-bottom: 3em;
}