/*********************
  Pagination
**********************/

.pagination {
    li {
        float: left;
        font-size: 1.2rem;
        line-height: 30px;
        border-radius: 2px;
        text-align: center;
        .active {
            background-color: #B39141;
            color: #ffffff;
        }
        a {
            border: 0;
            color: #444;
            transition: ease-in-out 0.3s;
        }
        a:active {
            background-color: #B39141;
            color: #ffffff;
        }
        a:hover {
            background-color: #B39141;
            color: #ffffff;
            transition: ease-in-out 0.3s;
        }
    }
} 

.pagination-dark {
    li {
        float: left;
        font-size: 1.2rem;
        line-height: 30px;
        border-radius: 2px;
        text-align: center;
        .active {
            background-color: #ee6e73;
        }
        a {
            border: 0;
            color: #eee;
            background-color: transparent;
            transition: ease-in-out 0.3s;
        }
        a:active {
            color: #000;
        }
        a:hover {
            background-color: #00417b;
        }
    }
}

.pag-circle .active a {
    border-radius: 50%;
}