/*********************
  Scrollspy
**********************/


@media (min-width: 768px) {
  .scrollspy {
    padding-left: 20px
  }
}

#scrollspy {
  margin-top: 20px;
  margin-bottom: 20px;
  .nav>li>a {
    display: block;
    padding: 4px 20px;
    font-size: 13px;
    font-weight: 500;
    color: #767676;
    border-radius: 0px;

  }
  .nav>li>a:focus,
  .nav>li>a:hover {
    padding-left: 19px;
    color: #563d7c;
    text-decoration: none;
    background-color: transparent;
    border-left: 1px solid #563d7c
  }
  .nav>.active:focus>a,
  .nav>.active:hover>a,
  .nav>.active>a {
    padding-left: 18px;
    font-weight: 700;
    color: #563d7c;
    background-color: transparent;
    border-left: 2px solid #563d7c
  }
  .nav .nav>li>a {
    padding-top: 1px;
    padding-bottom: 1px;
    padding-left: 30px;
    font-size: 12px;
    font-weight: 400
  }
  .nav .nav>li>a:focus,
  .nav .nav>li>a:hover {
    padding-left: 29px
  }
  .nav .nav>.active:focus>a,
  .nav .nav>.active:hover>a,
  .nav .nav>.active>a {
    padding-left: 28px;
    font-weight: 500
  }
}