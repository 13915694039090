/*!
 * Material Design for Bootstrap
 * Version: MDB FREE: 3.3.3
 *
 *
 * Copyright: Material Design for Bootstrap
 * www.mdbootstrap.com
 *
 * Read the license: http://mdbootstrap.com/wp-content/uploads/2015/09/MDB-License.pdf
 *
 * Atribution: Animate CSS, Twitter Bootstrap, Materialize CSS, Normalize CSS, Waves JS, WOW JS, Toastr, Chart.js , Hammer.js
 *
 */
  
@charset "UTF-8";  

// MDB Framework //
 
// Mixins
@import "mdb/_prefixer";
@import "mdb/_mixins";

@import "mdb/_color";

// Variables
@import "mdb/_variables";

// Reset
@import "mdb/_normalize";
 
//Global
@import "mdb/_global";
  
// Components  
@import "mdb/_roboto";
@import "mdb/_material-design-icons";
@import "mdb/_buttons";
@import "mdb/_navbar";
@import "mdb/_shadows";
@import "mdb/_typography";
@import "mdb/_cards";
@import "mdb/_dropdown";
@import "mdb/_tooltip";
@import "mdb/_scrollspy";
@import "mdb/_pagination";
@import "mdb/_panel";
@import "mdb/_carousel";
@import "mdb/_animations";
@import "mdb/_forms-basic";
@import "mdb/_video-background";
@import "mdb/_parallax";
@import "mdb/_hover-effects";
@import "mdb/_sections";
   
// Animations
@import "mdb/_waves";
    
// Helpers 
@import "mdb/_helpers";







 
