@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url('#{$icons-font-path}MaterialIcons-Regular.eot'); /* For IE6-8 */
    src: url('#{$icons-font-path}MaterialIcons-Regular.woff2') format('woff2'),
        url('#{$icons-font-path}MaterialIcons-Regular.woff') format('woff'),
        url('#{$icons-font-path}MaterialIcons-Regular.ttf') format('truetype');
}

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 19px;
    vertical-align: sub;
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';
}